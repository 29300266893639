.voidHeader {
}

.mapDiv{
    width: 100%;
    margin: 0 auto;
}

.map{
    width: 100%;
    height: 100vh;
}





@media only screen and (max-width : 1024px) {
    .onlyPC{
        display: none;
    }

    .onlymobile {
        display: block;
    }

    .voidHeader {
        height: 59px;
        height: 104px;
    }

    /* .mapDiv{
        width: 91.5%;
        margin: 0 auto;
        margin-top: 56px;
        margin-bottom: 32.5px;
    }

    .map{
        width: 100%;
        height: 236px;
    } */
}