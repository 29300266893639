body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*{
	font-family: 'Noto Sans KR', sans-serif;
	box-sizing: border-box;
}

*{
	-webkit-tap-highlight-color: transparent;
}


.sc-iBkjds{
	display: none !important;
}

a{
	-webkit-tap-highlight-color: transparent;
}

.chart-fill{
	opacity: 0;
	fill: url(#gradient);
}

.letsgo .chart-fill{
	animation: fill 2s linear 0.5s forwards;
}

.chart-line{
	fill: none;
	stroke : url(#gradient);
	stroke-dasharray: 2000;
	stroke-dashoffset: 2000;
	stroke-linecap: round;
	stroke-width: 2;
}

.letsgo .chart-line{
	animation: draw 2s linear 0.5s forwards;
}

@keyframes fill {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.4;
	}
}

@keyframes draw {
	0% {
		
	}
	100% {
		stroke-dashoffset: 0;
	}
}

iframe{
	border-radius: 10px;
}




@font-face {
    font-family: 'Paybooc_EB';
    src: url("styles/payboocextraboldsubset.woff") format('woff2'),
	url("styles/payboocextraboldsubset.ttf") format('truetype')
}

@font-face {
    font-family: 'Apple_SB';
    src: url("styles/AppleSDGothicNeoSB.woff2") format('woff2'),
	url("styles/AppleSDGothicNeoSB.ttf") format('truetype')
}



.MuiFab-root{
	display: none !important;
}

.loadingPageDiv{
	height: calc(var(--vh, 1vh) * 100);
}

.loadingPageLogo{
	width: 300px;
}

.fifthSection div .swiper .swiper_pagination{
}

.fifthSection div:nth-child(2) .swiper .swiper-pagination{
	
}

.iw_inner{
	font-weight: 400;
	touch-action: none;
	padding: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	line-height: 20px;
	letter-spacing: -0.2px;
	background-color: white;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);
}

.iw_inner_title{
	color: #0068c3;
	font-weight: 700;
	touch-action: none;
	margin-bottom: 7px;
	font-size: 17px;
}

.iw_inner_phonenumber{
	margin-top: 4px;
}

.iw_inner_phoneBtn{
	text-decoration-line: none;
	color: #1b49af;
}

@media only screen and (max-width : 1024px) {
	.MuiFab-root{
		display: flex !important;
		background-color: white !important;
	}

	.MuiDataGrid-columnHeaders{
	}

	.MuiDataGrid-main{
		font-size: 12px !important;
	}

	.css-h4y409-MuiList-root{
		padding: 0 !important;
	}

	.css-1ontqvh{
		padding: 0 !important;
	}

	.swiper-pagination-bullet-active{
		background-color: #1b49af !important;
	}

	.loadingPageLogo{
		width: 160px;
	}

	.replay{
		top: 80% !important;
	}
	
	.MuiDataGrid-columnHeadersInner{
		background-color: #e4e4e4;
	}

	.iw_inner{
		font-size: 14px;
	}

	.iw_inner_title{
		margin-bottom: 5px;
		font-size: 16px;
	}

	.iw_inner_phonenumber{
		margin-top: 2px;
	}

	@media only screen and (min-width : 500px){
		.loadingPageLogo{
			width: 300px;
		}
	}
}

#motionfadebox3{
	opacity: 0.2;
}

