.voidHeader {
    height: 82px;
}

.headerBar{
    height: 176px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #383838;
    letter-spacing: -0.76px;
    font-size: 30.5px;
}


.houseSvg {
    width: 18px;
    margin-bottom: 2.5px;
    margin-right: 20px;
}

.rightChevron {
    width: 8px;
    margin-right: 18px;
}

.rightChevron.second{
    margin-left: 18px;
}

.currentMenuViewer{
    width: 1150px;
    margin: 0 auto;
    display: flex;
    height: 58px;
    align-items: center;
}

.currentMenuViewerText_2{
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #525252;
}

.currentMenuViewerBoarder{
    height: 1px;
    opacity: 0.6;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.22);
}

.titleText {
    margin-top: 125px;
    text-align: center;
    font-size: 35.5px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.89px;
    color: black;
    margin-bottom: 85px;
}

.subTitleText{
    text-align: center;
    font-size: 20px;
    color: #6b7684;
    font-weight: 500;
    margin-bottom: 120px;
}

.feedBox{
    padding-top: 60px;
    padding-bottom: 60px;
}

.eachFeedBox{
    display: flex;
    width: 1050px;
    height: 182px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 80px;
    cursor: pointer;
}

.eachFeedTextBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eachFeedTextBox:hover .eachFeedTextBoxTitle{
    color: #1b49af;
}

.eachFeedTextBoxCategory{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 10px;
    color: #8b95a1;
}

.eachFeedTextBoxTitle{
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    color: #333d4b;
    margin-bottom: 10px;
    transition: all 0.2 ease-in-out;
}

.eachFeedTextBoxSubTitle{
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 10px;
    color: #4e5968;
}

.eachFeedTextBoxDate{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    color: #8b95a1;
}


.eachFeedImgBox{
    height: 100%;
}

.imgWrapper{
    height: 100%;
}

.eachFeedImg{
    width: 270px;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.onlymobile{
    display: none;
}


@media only screen and (max-width : 1024px) {

    .onlymobile{
        display: block;
    }

    .onlyPC{
        display: none;
    }

    .voidHeader {
        height: 59px;
        height: 104px;
    }

    .mobileTitleBox{
        width: 92%;
        max-width: calc(100% - 48px);
        margin: 0 auto;
    }


    .mobileTitleText{
        padding-top: 51px;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.4;
        color: #333d4b;
        text-align: center;
    }

    .mobileSubTitleText{
        font-size: 15px;
        line-height: 1.4;
        font-weight: 500;
        text-align: center;
        color: #6b7684;
        padding-top: 12px;
    }

    .mobileFeedBoxTitle{
        padding-top: 60px;
        width: 92%;
        max-width: calc(100% - 48px);
        margin: 0 auto;
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgb(51, 61, 75);
    }


    .eachFeedBox{
        width: 92%;
        max-width: calc(100% - 48px);
        flex-direction: column-reverse;
        height: auto;
        margin-bottom: 38px;
    }


    
    .eachFeedTextBoxCategory{
        margin-top: 22px;

        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 10px;
        margin-bottom: 0px;
        color: #8b95a1;
    }
    
    .eachFeedTextBoxTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 10px;
        color: #333d4b;
        transition: all 0.2 ease-in-out;
    }

    .eachFeedTextBoxSubTitle{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #4e5968;
        margin-bottom: 10px;
    }
    
    
    .eachFeedTextBoxDate{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        color: #8b95a1;
    }
    
    
    


    .eachFeedTextBox{
        display: block;
    }

    .eachFeedImgBox{
        width: 100%;
        height: 62.1vw;
    }
    
    .imgWrapper{
        width: 100%;
        height: 100%;
    }
    
    .eachFeedImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }
}