.voidHeader {
    height: 82px;
}


.onlymobile{
    display: none;
}


.tableDivDiv{
    padding-top: 120px;
    width: 1150px;
    margin: 0 auto;
}

.deleteBtnWrapper{
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
}

.registerTitle{
    border-top: 2px solid black;
    margin: 0 auto;
    margin-top: 100px;
    width: 1150px;
}



@media only screen and (max-width : 1024px) {
    .onlyPC{
        display: none;
    }

    .onlymobile {
        display: block;
    }

    .voidHeader {
        height: 59px;
        height: 104px;
    }

    .tableDiv{
        height: 400px !important;
    }

    .tableDivDiv{
        width: 100%;
        overflow-x: auto;
    }

    .registerTitle{
        border-top: 0px;
        padding-top: 100px;
        width: 100%;
    }


}