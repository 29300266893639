.main{
    max-width: 500px;
    margin: 0 auto;
}

.header{
    width: 100%;
    height: 140px;
    background-color: #1b49af;
    position: relative;
}

.headerTitle{
    width: 150px;
    margin: 0 auto;
    color: white;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    height: 80px;
    display: flex;
    align-items: center;
}

.decorator{
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    background-color: white;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
}

.innerContents{
    width: 88%;
    margin: 0 auto;
    margin-top: 0px;
}

.titleText{
    font-weight: 500;
    font-size: 16px;
    color: #263238;
}

.numberText{
    margin-top: 10px;
    color: #1b49af;
    font-weight: 500;
    font-size: 15px;
    position: relative;
    display: inline-block;
}

.numberText span{
    font-weight: 700;
    font-size: 60px;
    margin-right: 5px;
}

.triangle{
    position: absolute;
    left:   calc(100% + 10px);
    background-color: #37474f;
    height: 12px;
    top: 43.8px;
    border-radius: 3px;
    width: 12px;
    transform: rotate(45deg);
}

.speechBubble{
    position: absolute;
    top: 32px;
    left: calc(100% + 15px);
    background-color: #37474f;
    color: white;
    font-weight: 400;
    font-size: 12px;
    width: 172px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.subDataDiv{
    margin-top: 35px;
    display: flex;
}

.justVerticalBorder{
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    display: flex;
    align-items: center;
}

.justVerticalBorderInner{
    height: 14px;
    width: 100%;
    background-color: #eaeaea;
}

.subDataTitle{
    font-size: 13px;
    color: #717171;
}

.subDataDate{
    color: #263238;
    margin-top: 12px;
    font-weight: 500;
}

.contentsTitle{
    display: flex;
    justify-content: center;
    margin-top: 80px;
    font-size: 36px;
    font-weight: 700;
}

.contentsDescription{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.cautionContents{
    margin-top: 30px;
    padding-left: 16px;
    padding-top: 20px;
    padding-bottom: 12px;
    background-color: #f7f7f9;
    border-radius: 8px;
}

.cautionContentsTitle{
    color: #1b49af;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 14px;
}

.cautionContentsText{
    font-size: 11px;
    margin-bottom: 10px;
    color: #455a64;
}

.cautionContentsText span{
    font-weight: 900;
    margin-right: 6px;
}

.infoContents{
    margin-top: 30px;
    border-top: 8px solid #f7f7f9;
    border-bottom: 8px solid #f7f7f9;
}

.infoContentsInnerDescription{
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #eceff1;
}

.infoContentsInnerDescription.first{
    border: 0;
}

.infoTitle{
    font-weight: 500;
    font-size: 13px;
}

.infoDescription{
    font-weight: 500;
    font-size: 13px;
}




