.atag {
    -webkit-tap-highlight-color: transparent;
}

.openTitleText {
    margin-top: 125px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 35.5px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.89px;
    color: #353535;
    font-weight: 700;
}

.openTitleText.second{
    margin-top: 0px;
    margin-bottom: 90px;
}

.englishWordTitleText{
    text-align: center;
    font-size: 35.5px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.89px;
    color: #353535;
    font-weight: 700;
}

.divTitleText{
    font-size: 35.5px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.89px;
    text-align: center;
    color: white;
}

.descriptionText{
    text-align: center;
    font-size: 16.5px;
    font-weight: 100;
    color: #7a7a7a;
    letter-spacing: -0.41px;
    margin-bottom: 64px;
}

.descriptionText2{
    text-align: center;
    font-size: 20.5px;
    font-weight: 100;
    color: #2a2a2a;
    letter-spacing: -0.41px;
    margin-bottom: 64px;
    margin-top: 12px;
    line-height: 1.4;
}


.ceoVideo {
    position: absolute;
    /* top : 826px;
    left: 50%;
    transform: translate(-50%, 0); */
    width: 900px;
}

.videoBox{
    /* margin: 0 auto; */
    overflow: hidden;

}

.vimeoWrapper{
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}



.voidHeader {
    height: 82px;
}

.subHeader {
    position: relative;
    height: 450px;
    background-size: cover;
    background-position: 0% 45%;
    background-size: 100%;
    filter: grayscale(1);
}

.subHeaderText {
    position: absolute;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-size: 35.5px;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subHeaderTextTitle {
    margin-bottom: 16px;
}

.blackFilter {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
}


.houseSvg {
    width: 18px;
    margin-bottom: 2.5px;
    margin-right: 20px;
}

.rightChevron {
    width: 8px;
    margin-right: 18px;
}

.rightChevron.second {
    margin-left: 18px;
}

.currentMenuViewer {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    height: 58px;
    align-items: center;
}

.currentMenuViewerText_1 {
    font-weight: 100;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #525252;
}

.currentMenuViewerText_2 {
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #525252;
}

.currentMenuViewerBoarder {
    height: 1px;
    opacity: 0.6;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.22);
}

.titleText {
    margin-top: 125px;
    margin-bottom: 64px;
    text-align: center;
    font-size: 35.5px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.89px;
    color: #353535;
}



.teachersList {
    display: flex;
    width: 1150px;
    margin: 0 auto;
}

.eachTeacherNameDiv {
    cursor: pointer;
    flex-grow: 1;
    border: 1px solid #dddddd;
    border-right: 0;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid black;
}

.eachTeacherName {
    font-size: 16.5px;
    color: #595959;

    letter-spacing: -0.83px;
}

.eachTeacherNameDiv:last-child {
    border-right: 1px solid #dddddd;
}

.eachTeacherNameDiv.active {
    border: 1px solid black;
    border-bottom: 0;
}

.eachTeacherName.active {
    color: #1b49af;
    font-weight: 700;
}

.eachTeacherSchool {
    margin-top: 5px;
    font-size: 13.5px;
    font-weight: 300;
    color: #a0a0a0;
    letter-spacing: -0.68px;
}

.eachTeacherSchool.none {
    display: none;
}

.sectionSubTitle {
    margin-top: 64px;
    text-align: center;
    font-weight: 700;
    font-size: 30.5px;
    letter-spacing: -0.76px;
    margin-bottom: 20px;
    color: #353535;
    line-height: 1.5;
    white-space: pre-wrap;
}

.clickMenuDiv {
    white-space: pre-wrap;
}


.listTitleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 500px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 40px;
}

.listTitleDiv.wide{
    width: 850px;
}

.listTitleDiv.none {
    display: none;
}

.eachList {
    height: 36px;
    line-height: 36px;
    padding-right: 14px;
    padding-left: 14px;
    font-size: 14.5px;
    color: #6d6d6d;
    cursor: pointer;
}

.eachListhTitleDiv {
    display: flex;
    align-items: center;
}

.eachListBorder {
    height: 13px;
    margin-top: 3px;
    width: 1px;
    border-right: 1px solid #b7b7b7;
}

.eachListBorder.none {
    display: none;
}

.eachList.active {
    background-color: #272e3a;
    color: white;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14.5px;
}

.submenuDescriptionTextDiv {
    width: 1150px;
    margin: 0 auto;
    margin-bottom: 18px;
    font-family: "Apple_SB";
    font-size: 20px;

    justify-content: center;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.6;
}

.submenuDescriptionTextDiv.moreMargin{
    margin-bottom: 70px;
}

.submenuDescriptionDiv {
    display: flex;
    justify-content: center;
}

.submenuText {
    font-size: 18px;
    font-family: "Apple_B";
    white-space: pre-wrap;
}

.check {
    width: 24px;
}

.checkDiv {
    margin-right: 12px;
}




.chartDiv {
    width: 1150px;
    margin: 0 auto;
}

.animateDivWrapper {
    position: absolute;
    width: 100%;
}

.imgDiv {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.submenuImage {
    width: 80%;
    object-fit: contain;
    max-height: 600px;
}

.submenuImage.bigSize{
    width: 100%;
    max-height: none;
}

.mealDiv {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    margin-top: 64px;
    justify-content: space-between;
}

.mealBoxWrapper{
    width: 100%;
}

.mealBoxWrapper2{
    width: 100%;
}

.mealBox {
    width: 90%;
    background-color: #efefef;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
}

.mealBoxTitle {
    color: black;
    text-align: center;
    font-family: "Apple_SB";
    font-size: 22px;
    margin-bottom: 32px;
}

.mealBox.first {
    margin-right: 8%;
}

.mealImagesDiv {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 160px;
    column-gap: 20px;
}

.mealImageDiv {
    background-color: white;
}

.mealImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottomBox {
    background-color: #f5f5f6;
    padding-top: 32px;
    padding-bottom: 120px;
    font-size: 24px;
}

.differentiationDiv {
    width: 1150px;
    margin: 0 auto;
}

.bottomBoxTitle {
    margin-top: 64px;
    text-align: center;
    font-weight: 700;
    font-size: 30.5px;
    letter-spacing: -0.76px;
    color: #353535;
    line-height: 1.5;
    white-space: pre-wrap;
}


.differentiationTitle {
    width: 246px;
    height: 57px;
    border-radius: 15px;
    background-color: #ebebeb;
    font-weight: 700;
    color: #353535;
    font-size: 21px;
    letter-spacing: -0.53px;
    text-align: center;
    line-height: 57px;
    margin-top: 80px;
    margin-bottom: 24px;
}

.differentiationList {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid #dbdbdb;
}

.differentiationList.first {
    border-top: 2px solid #dbdbdb;
}

.differentiationList_1 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #353535;
    margin-right: 74px;
    width: 120px;
}

.differentiationList_2 {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
    color: #353535;
}

.differentiationList_2.multiLine div {
    margin-bottom: 12px;
}

.differentiationList_2.multiLine div:last-child {
    margin-bottom: 0;
}

.lastBtnDiv {
    margin-top: 88px;
    display: flex;
    justify-content: center;
}

.onlymobile {
    display: none;
}

.atag,
.atag:hover,
.atag:active,
.atag:visited,
.atag:focus {
    text-decoration: none;
}

.mapDiv{
    width: 1150px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 49px;
}

.map{
    width: 1150px;
    height: 517px;
}

.mapDescription {
    width: 1150px;
    margin: 0 auto;
    padding-bottom: 90px;
    border-bottom: 3px solid #ebebeb;
    margin-bottom: 90px;   
}

.mapDescription2 {
    width: 600px;
    margin: 0 auto;
    border-bottom: 3px solid #ebebeb;
    margin-bottom: 70px;   
}

.subMapDescription{
    width: 900px;
    margin: 0 auto;
}

.mapDescriptionPlace{
    display: flex;
    margin-bottom: 28px;
}

.placeSvg{
    width: 18px;
    margin-right: 24px;
}

.callSvg {
    width: 22px;
    margin-right: 22px;
}

.busSvg {
    width: 22px;
    margin-right: 22px;
}

.placeText {
    font-weight: 700;
    font-size: 21px;
    color: #353535;
    letter-spacing: -0.53px;
}

.placeText_1{
    margin-bottom: 15px;
}

.oddList{
    position: relative;
}

.oddList.notFirst{
    margin-top: 120px;
}

.oddList::before{
    left: 0;
    border-radius: 0 260px 260px 0;
    content: '';
    position: absolute;
    height: 75%;
    width: 55%;
    background-color: #ebfbfe;
    background-color: #f7f7f9;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.oddList.even::before{
    left: auto;
    right: 0 !important;
    border-radius: 260px 0 0 260px;
}

.inner{
    display: flex;

    align-items: center;
    gap: 40px;

    width: 100%;
    max-width: 1520px;
    max-width: 1250px;
    margin: 0 auto;
}

.imgWrap{
    width: calc(55% - 20px);
    display: flex;
    gap: 20px;
}

.imgWrap.docu{
    width: 75%;
    display: block;
}

.imgBox{
    width: calc(50% - 10px);
    max-width: fit-content;
}

.docu .imgBox{
    width: 75%;
    height: 400px;
}

.exams.imgWrap{
    background-color: red !important;
}

.exams .imgBox{
    width: 70%;
    margin-left: 60px;
}

.txtWrap{
    width: calc(45% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.txtWrap.docu.oddDocu{
    width: 40%;
    padding-right: 40px;
}

.txtWrap.docu.evenDocu{
    width: 40%;
    padding-left: 40px;
}


.txtBox span{
    font-weight: 500;
    font-size: 28px;
    line-height: 1.78em;
    letter-spacing: -0.025em;
    color: #48484b;
    color: #1b49af;
}

.txtBox h6{
    font-weight: 700;
    font-size: 60px;
    color: #00cdf5;
    color: black;
    font-size: 35.5px;
    line-height: 48px;
}

.txtWrapDescription{
    margin-top: 17px;
    line-height: 1.8;
}

.even .imgWrap{
    order: 1;
}

.even .txtWrap{
    display: flex;
    justify-content: flex-start;
}

.even{

}

.justHeight{
    height: 200px;
}

.englishWordDiv{
    background-color: #f5f5f6;
}

.englishWordBody{
    width: 1150px;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 70px;
    margin-bottom: 120px;
}

.englishWordTitleText{
    margin-bottom: 70px;
}

.englishWordGridBody{
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    width: 100%;
    column-gap: 25px;
}

.englishWordGrid{
    background-color: white;
    border-radius: 20px;
    padding-top: 36px;
    padding-bottom: 0px;
    padding-left: 40px;
    padding-right: 40px;
}

.englishWordGridTitle{
    line-height: 1.2858042857;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.011em;
    color: #1b49af;
}

.englishWordGridDescription{
    margin-top: 0.3em;
    line-height: 1.5263157895;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.012em;
}

.englishWordImgWrapper{
    padding-top: 100px;
    width: 100%;
}

.englishWordImgWrapper.secondWrapper{
    padding-top: 40px;
}

.englishWordImgDiv{
    width: 100%;
    display: flex;
    justify-content: center;
}


.englishWordImg1{
    width: 100%;
    object-fit: cover;
}

.englishWordImg2{
    width: 90%;
    object-fit: cover;
}

.thirdSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 240px;
}

.thirdSection .txtBox{
    text-align: center;
    margin-bottom: 48px;
}

.videoBox{
    width: 900px;
    margin: 0 auto;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
}

.thumbnail1{
    width: 100%;
    object-fit: cover;
}

.thirdSectionComponent{
    width: 100%;
}


@media only screen and (max-width : 1520px){
    .inner{
        width: 93.75%;
    }
}


@media only screen and (min-width : 1024px) {
    .secondSectionBackground{
        margin-bottom: 0px;
    }

    .ceoVideo{
        position: static;
        margin: 0 auto;
        margin-bottom: 80px;
    }

    .vimeoWrapper{
        width: 100%;
    }


}


@media only screen and (max-width : 1024px) {
    .onlyPC {
        display: none;
    }

    .thirdSection{
        margin-top: 120px;
    }

    .videoBox{
        width: 100%;
        border-radius: 6px;
    }

    .thirdSection .txtBox{
        margin-bottom: 28px;
    }

    .inner{
        flex-wrap: wrap;
    }

    .onlymobile {
        display: block;
    }

    .docu.imgWrap{
        width: 100%;
    }

    .docu .imgBox{
        width: 100%;
        height: auto;
    }

    .even .imgWrap{
        order: 0;
    }

    .txtWrap{
        width: 100% !important; 
        justify-content: flex-start !important;
        padding-left: 30px !important;
    }

    .txtBox span{
        font-size: 20px;

        font-size: 17px;
    }

    .txtBox h6{
        font-size: 23px;

        font-size: 20px;
        line-height: 1.4;
    }

    .exams .imgBox{
        width: 100%;
        margin-left: 0;
    }

    .exams{
        margin-top: 120px !important;
    }

    .txtWrapDescription{
        line-height: 1.5;
        font-size: 14px;
    }

    .voidHeader {
        height: 59px;
        height: 104px;

    }

    .subHeader {
        min-width: 0;
        background-position: 40% 0%;
        height: 256.5px;
    }

    .subHeaderText {
        font-size: 21px;
        width: 100%;
    }

    .openTitleText{
        margin-top: 64px;
        margin-top: 0px;
        padding-top: 64px;
        font-size: 23px;
        letter-spacing: -0.43px;
        line-height: 36px;
        margin-bottom: 12px !important;
        font-weight: 800;
    }

    .openTitleText.second{
        margin-bottom: 70px !important;
        padding-top: 80px;
    }

    .englishWordTitleText{
        font-size: 23px;
        letter-spacing: -0.43px;
        line-height: 33px;
        font-weight: 800;
    }

    .descriptionText{
        font-size: 14px;
    }

    .divTitleText{
        
    }


    .subHeaderTextTitle {
        margin-bottom: 12px;
    }

    .mainDescriptionDiv {
        width: 90.6%;
        margin: 0 auto;
    }

    .sectionSubTitle {
        font-size: 22px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .bottomBox {
        padding-top: 0px;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .bottomBoxDiv {
        margin: 0 auto;
        width: 90.6%;
    }

    .bottomBoxTitle {
        margin-top: 0px;
        font-size: 22px;
    }

    .differentiationDiv {
        width: 100%;
    }

    .differentiationTitle {
        font-size: 13.5px;
        letter-spacing: -0.34px;
        width: 160px;
        height: 37px;
        border-radius: 7.5px;
        line-height: 37px;
        margin-top: 65.5px;
        margin-top: 60px;
        margin-bottom: 15px;
    }

    .differentiationTitle.questionDataTitle {
        margin-top: 40px;
    }

    .differentiationList {
        padding-top: 21px;
        padding-bottom: 20.5px;
        padding-top: 9.25px;
        padding-bottom: 9.25px;
        border-bottom: 1px solid #dbdbdb;
    }

    .differentiationList.first {
        border-top: 1px solid #dbdbdb;
    }

    .differentiationList_1 {
        margin-left: 5px;
        font-size: 13px;
        letter-spacing: -0.33px;
        margin-right: 25px;
        margin-right: 20px;
        padding-top: 6px;
        flex-shrink: 0;
        width: 80px;
    }

    .differentiationList_2 {
        font-size: 11.5px;
        line-height: 23px;
        letter-spacing: -0.29px;
    }

    .lastBtnDiv.onlymobile {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 56.5px;
        margin-top: 60px;
    }


    .mobileTitleList {
        margin-top: 19.5px;
        display: flex;
        overflow: auto;
        border-bottom: 0.5px solid #e7e7e7;
        margin-bottom: 49px;
        padding-left: 20px;
        padding-top: 17px;
        padding-right: 240px;
    }

    .mobileTitleList::-webkit-scrollbar {
        background-color: #f5f5f5;
        width: 0px !important;
        height: 0px !important;
    }

    .mobileTitleList::-webkit-scrollbar-thumb {
        background-color: #bbbcb6;
        border-radius: 0px;
    }

    .mobileEachListTitle {
        flex-shrink: 0;
        font-size: 14px;
        letter-spacing: -0.74px;
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 16px;
        margin-right: 18px;
        font-family: "Apple_SB";
        line-break: strict !important;
    }

    .mobileEachListTitle.active {
        border-bottom: 2.5px solid #1b49af;
    }

    .mobileListTitleDiv {
        width: 80%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 30px;
        box-sizing: border-box;
    }

    .mobileEachListTitleDiv {
        text-align: center;
        padding-top: 14px;
        padding-bottom: 14px;
        border: 1px solid #1b49af;
        color: #1b49af;
        font-size: 14px;
        font-family: "Apple_SB";
        box-sizing: border-box;
    }

    .mobileEachListTitleDiv:nth-child(2n){
        border-left: 0;
    }

    .mobileEachListTitleDiv:nth-child(3){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(4){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(5){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(6){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(7){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(8){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(9){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(10){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(11){
        border-top: 0;
    }

    .mobileEachListTitleDiv:nth-child(12){
        border-top: 0;
    }



    .mobileEachListTitleDiv.even{
        border-right: 1px solid #1b49af;
    }



    .mobileEachListTitleDiv.active {
        background-color: #1b49af;
        color: white;
    }

    .mobileDescriptionWrapper{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 16px;
    }

    .mobileDescriptionWrapper.moreBottomMargin{
        margin-bottom: 40px;
    }
    
    .submenuDescriptionTextDiv{
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
        line-height: 1.4;
        text-align: center;
        white-space: pre-wrap;
    }

    .checkDiv{
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
    }

    .check{
        width: 20px;
    }

    .mobileImageWrapper{
        width: 100%;
        margin: 0 auto;
    }

    .mobileSubmenuImage{
        width: 100%;
        object-fit: cover
    }

    .differentiationList_2.multiLine div {
        margin-bottom: 0px;
    }








    .mobileListTitleDiv.none {
        display: none;
    }

    .ceoVideo{
        position: static;
    }

    .ceoVideo img{
        width: 100%;
    }

    .videoBox{
        width: 93.75%;
        margin: 0 auto;
        overflow: hidden;
    }

    .ceoVideo{
        position: static;
        transform: translate(0, 0);
        width: 91.1%;
        margin: 0 auto;
        margin-bottom: 30.5px;
        margin-bottom: 0px;
        margin-bottom: 50px;
    }

    .mapDiv{
        width: 91.5%;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .map{
        width: 100%;
        height: 236px;
    }

    .mapDescriptionDiv {
        margin-bottom: 40px;
        border-bottom: 5.5px solid #eaeaea;
    }

    .mapDescription {
        padding: 0;
        width: 91.5%;
        margin: 0 auto;
        border: 0;
        padding-bottom: 13.5px;
    }

    .subMapDescription{
        width: 100%;
    }

    .mapDescriptionPlace{
        margin-bottom: 17px;
    }

    .mapDescriptionPlace.second{
        align-items: center;
    }

    .placeText {
        font-size: 13.5px;
        letter-spacing: -0.34px;
    }

    .placeText_1 {
        margin-bottom: 11px;
    }

    .metroPlaceText_1 {
        margin-bottom: 5px;
    }

    .metroPlaceText {
        line-height: 23px;
    }

    .svgBox{
        width: 29px;
        flex-shrink: 0;
    }

    .svgBox.first{
        padding-left: 3px;
    }

    .placeSvg{
        width: 11px;
    }

    .callSvg {
        width: 13.5px;
    }

    .busSvg {
        width: 14.5px;
    }

    .imgWrap{
        width: 100%;
        justify-content: center;
    }

    .txtWrap{
        width: 100%;
        justify-content: center;
    }

    .justHeight{
        height: 100px;
    }

    .differentiationTitle{
        margin-top: 0px;
    }

    .textBoxGo{
        width: 90% !important;
    }

    .textBoxTitle{
        font-size: 18px !important;
        
    }

    .textBoxWrapper{
        width: 45% !important;
    }

    .textBoxDescription{
        font-size: 12px !important;
    }

    .textBoxWrapper{
        width: 80% !important;
        text-align: "center !important";
    }

    .textBoxWrapper.second{
        padding-bottom: 65px !important;
    }

    .mapDescription2{
        width: 100% !important;
    }

    .mapDescription2{
        display: none;
    }

    .textBoxDescription{
        display: flex;
        justify-content: center;
    }

    .justBorder{

    }

    .textBoxDescription2{
        text-align: center;
        font-size: 11px !important;
    }

    .textBoxGo{
        margin-bottom: 60px !important;
    }

    .textBoxWrapperText{
        font-size: 12px !important;
    }

    .textBoxTitle{
        font-size: 14px !important;
        font-weight: 600;
    }

    .txtWrap.docu.oddDocu{
        padding-right: 0px;
    }
    
    .txtWrap.docu.evenDocu{
        padding-left: 0px;
    }

    .realFirstSection{
        background-color: #f5f5f6;
    }

    .justBorder2{
    }



    .englishWordBody{
        width: 90.6%;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 70px;
        margin-bottom: 90px;
    }
    
    .englishWordTitleText{
        margin-bottom: 70px;
    }
    
    .englishWordGridBody{
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        column-gap: 0px;
        row-gap: 15px;
    }
    
    .englishWordGrid{
        background-color: white;
        padding-top: 36px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 20px;
    }

    .forMobileEnglishWord{
        padding-left: 40px;
        padding-right: 40px;
    }
    
    .englishWordGridTitle{
        line-height: 1.2858042857;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0.011em;
        color: #1b49af;
    }
    
    .englishWordGridDescription{
        margin-top: 0.6em;
        line-height: 1.663157895;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.012em;
    }
    
    .englishWordImgWrapper{
        padding-top: 44px;
        width: 100%;
    }
    
    .englishWordImgWrapper.secondWrapper{
        padding-top: 40px;
    }
    
    .englishWordImgDiv{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    
    .englishWordImg1{
        width: 95%;
        object-fit: cover;
    }
    
    .englishWordImg2{
        width: 85%;
        object-fit: cover;
    }

    .secondSection{
        overflow: hidden;
    }



}

.modalImg{
    width: 100%;
    object-fit: cover;
}

.justBorder{
    border-right: 1px solid black;
}

.justBorder2{
    margin: 0 auto;
    border-bottom: 2px solid black;
    width: 80px;
    margin-bottom: 20px;
}


.imgWrap{
    opacity: 0;
}

.txtWrap.oddDocu.docu{
    opacity: 0;
    transform: translateX(100px);
}

.txtWrap.evenDocu.docu{
    opacity: 0;
    transform: translateX(-100px);
}