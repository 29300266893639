:root {
    --font: "Pretendard";
    --gray: #6E757D;
    --gray2: #B3B8BF;
    --backgray: #F3F4F6;
    --black: #1D1D1F;
    --blue: #3182F6;
}

* {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.dailyreport {
    background-color: #F3F4F6;
    padding-bottom: 1rem;
    max-width: 500px;
    margin: 0 auto;
}

.logoHeader {
    padding-top: 0.87rem;
    width: 86.666%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainFirstBack{
    padding-top: 1rem;
    padding-left: 1.5rem;
    background: linear-gradient(180deg, #FFF 9.2%, #F1F2F9 35.07%, #DDE3F0 58.68%, #DBE2EF 100%);
    padding-bottom: 1.34rem;
    padding-right: 1.5rem;
}

.mainShare{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.12rem;
}

.mainShareBtn{
    font-family: var(--font);
}

.mainTitle {
    color: #14171E;
    font-family: var(--font);
    font-size: 1.5625rem;
    /* font-style: normal; */
    font-weight: 700;
    line-height: normal;
}

.mainDate {
    color: #343D4C;
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2.31rem;
    margin-bottom: 6.63rem;
}

.logo {
    width: 5.8125rem;
}

.logoDate {
    color: #6A7584;
    text-align: right;
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.titleDiv {
    margin-top: 2.41rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #343D4C;
    text-align: center;
    font-family: var(--font);
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.realTitle {
    color: #343D4C;
    text-align: center;
    font-family: var(--font);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.69rem;
}

.realTitle2 {
    margin-top: 0.37rem;
}

.dailyMainSvgDiv {
    margin-top: 4.13rem;
    padding-bottom: 5.05rem;
}

.mainSvgDiv{
    display: flex;
    justify-content: center;
}


.justGap {
    height: 1rem;
}

.compBody {
    padding-top: 1.62rem;
    background-color: white;
    padding-bottom: 1.62rem;
}

.compBody.noBottomPadding{
    padding-bottom: 0;
}

.compTitleDiv {
    width: 86.666%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.compTitle1 {
    font-family: var(--font);
    color: var(--black);
    font-size: 1.25rem;
    font-weight: 700;
    width: 86.666%;
    margin: 0 auto;
}

.compTitle2 {
    font-family: var(--font);
    color: var(--black);
    font-size: 1.25rem;
    font-weight: 700;
}

.compTitleBreak {
    line-height: normal;
}

.compSubTitle1 {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 0.82rem;
    color: var(--gray);
    font-weight: 500;
    letter-spacing: 0.04rem;
    font-family: var(--font);
}

.compSubTitle1 span {
    color: var(--blue);
}

.compSubTitle2 {
    font-family: var(--font);
    color: var(--gray);
    font-size: 0.8125rem;
    font-weight: 500;
}

.compSubTitle3 {
    font-family: var(--font);
    color: var(--gray2);
    line-height: normal;
    font-weight: 400;
    font-size: 0.9375rem;
    padding-top: 0.38rem;
    width: 86.666%;
    margin: 0 auto;
    font-style: normal;
}

.compSubTitle4 {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 0.88rem;
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.035rem;
}

.myPatrolResult {
    padding-top: 2.5rem;
}

.patrolLoading{
    width: 86.666%;
    margin: 0 auto;
    height: 21.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.myPatrol {
    width: 86.666%;
    margin: 0 auto;
}

.oneBlock {
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 0.62rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.oneBlock.odd {
    justify-content: flex-end;
}


.eachBlock {
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.noneBlockCircle {
    font-family: var(--font);
    font-size: 0.55rem;
    border-radius: 50%;
    border: 1px solid #E5E8EB;
    color: var(--gray);
    width: 85%;
    aspect-ratio: 1/1;
    max-width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noneBlock {
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachBlockText {
    font-family: var(--font);
    color: var(--blue);
    font-size: 0.55rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    margin-top: 0.1rem;
}

.eachBlockTime {
    font-family: var(--font);
    color: var(--gray);
    font-size: 0.55rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0075rem;
    margin-top: 0.1rem;
}

.whichSvg {
    width: 1.8rem !important;
}

.ourPatrolResult {
    padding-top: 2.44rem;
}

.ourPatrolList {
    width: 86.666%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ourPatrolListDiv {
    width: 6.2rem;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.dot {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
}

.ourPatrolListDiv0 .dot {
    background-color: #FFC84D;
}

.ourPatrolListDiv1 .dot {
    background-color: #F76470;
}

.ourPatrolListDiv2 .dot {
    background-color: #4FC578;
}

.ourPatrolListText {
    font-family: var(--font);
    font-size: 0.75rem;
    font-size: 10px;
    color: var(--gray);
}

.ourPatrolGraph {
    padding-top: 2.44rem;
}

.ourPatrolGraphDiv {
    display: flex;
    justify-content: space-between;
    width: 86.666%;
    margin: 0 auto;
}

.ourPatrolGraphClass {
    width: 1.5rem;
}

.ourPatrolGraphPics {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 9.375rem;
    width: 1.5rem;
    width: 100%;
}

.ourPatrolGraphWrapper {
    position: relative;
}

.ourPatrolGraphPicText {
    font-size: 0.5625rem;
    color: var(--gray2);
    font-family: var(--font);
    font-weight: 500;
    position: absolute;
    top: -15px;
    width: 1.5rem;
}

.ourPatrolGraphPic {
    width: 0.4rem;
    border-radius: 0.125rem 0.125rem 0rem 0rem;
    transition: all 300ms ease-out;
}

.ourPatrolGraphPic0 {
    background-color: #FEC341;
}

.ourPatrolGraphPic1 {
    background-color: #F76470;
}

.ourPatrolGraphPic2 {
    background-color: #15C47F;
}

.ourPatrolClassNumber {
    font-size: 0.5rem;

    font-size: 10px !important;

    color: var(--gray);
    font-family: var(--font);
    font-weight: 500;
    margin-top: 0.25rem;
    width: 100%;
    text-align: center;
}

.tableHeader {
    background-color: var(--backgray);
    padding-top: 0.91rem;
    padding-bottom: 0.91rem;
}

.tableHeader .tableRow {
    font-family: var(--font);
    color: var(--gray);
    font-weight: 500;
}

.tableRow {
    width: 86.666%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 17fr 35fr 35fr 13fr;
}

.attendance .tableRow {
    grid-template-columns: 28fr 21fr 51fr;
}

.bodyCol {
    height: 2.875rem;
    display: flex;
    align-items: center;
    padding-right: 1.25rem;
}

.tableText {
    font-family: var(--font);
    color: var(--gray);
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.015rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    /* 자동 줄바꿈 활성화 */
}

.zeroBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.38rem;
}

.svgWrapper {
    padding-bottom: 1.5rem;
}

.zeroText {
    font-family: var(--font);
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 1.2rem;
}

.barGraphBody {
    width: 86.666%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.eachBarDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eachBarTimeText {
    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0.75rem;
}

.eachBarDiv0 .eachBarTimeText {
    color: var(--black);
}

.eachGraphDiv {
    height: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.eachBarCrownDiv {
    height: 1.5rem;
    margin-bottom: 0.2rem;
}

.eachGraph {
    width: 4.375rem;
    border-radius: 0.625rem;
    transition: all 500ms ease-out;
}

.eachBarDiv0 .eachGraph {
    height: 80%;
    background-color: #1E6EF4;
}

.eachBarDiv1 .eachGraph {
    background-color: #E3E8EB;
}

.eachBarDiv2 .eachGraph {
    background-color: #EF4452;
}

.eachBarName {
    padding-top: 1.19rem;
    color: var(--gray);
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eachBarDiv0 .eachBarName {
    color: var(--black);
}

.eachBarSubText {
    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 0.19rem;
}

.nonSelect {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.canvasDiv {
    width: 86.666%;
    margin: 0 auto;

    margin-top: 3.25rem;
    height: 11rem;
    margin-bottom: 1.5rem;
    position: relative;
}

.canvas {
    position: absolute;
    z-index: 1;
}

.canvas2 {
    position: absolute;
    z-index: 2;
}

.chartDateDiv {
    width: 86.666%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.eachDateDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eachDate {
    color: var(--gray2);
    text-align: center;
    font-family: var(--font);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

}

.eachDay {
    color: var(--gray2);
    text-align: center;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

}

.chartNameDiv {
    display: flex;
    width: 86.666%;
    margin: 0 auto;
    justify-content: center;
    padding-top: 1.81rem;
}

.nameDiv {
    font-family: var(--font);
    font-size: 0.8125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.line {
    width: 1.4375rem;
    height: 0.25rem;
    margin-right: 0.38rem;
}

.myNameDiv .line {
    background-color: var(--blue);
}

.averageNameDiv .line {
    background-color: var(--gray);
}

.myNameDiv {
    color: var(--blue);
    margin-right: 0.375rem;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

}

.averageNameDiv {
    color: var(--gray);
    margin-left: 0.375rem;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

}

.canvasInfoTab {
    visibility: hidden;
    z-index: 99;
    position: absolute;
    width: 5.5rem;
    height: 3rem;
    border-radius: 0.5rem;
    background: rgba(243, 244, 246, 0.95);
    padding-left: 0.5rem;
    padding-top: 0.25rem;
}

.dateInfo {
    color: var(--black);
    font-family: Pretendard;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0125rem;
    margin-bottom: 0.19rem;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.myTimeInfo {
    display: flex;
    align-items: center;
    margin-bottom: 0.19rem;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.averageTimeInfo {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.infoDot {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.myTimeInfo .infoDot {
    background-color: var(--blue);
}

.averageTimeInfo .infoDot {
    background-color: var(--gray);
}

.myTime {
    color: var(--blue);
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.01125rem;
}

.averageTime {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.01125rem;
}

.rankingMenuDiv {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 1.88rem;
    display: flex;
    justify-content: space-between;
}

.firstSmallMenu {
    width: 43%;
}

.secondSmallMenu {
    width: 46%;
}

.rankingBodyDate {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 0.87rem;
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rankingImagesDiv {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.rankingImageDiv {
    height: 4.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.rankingImageDiv1 .rankingFace {
    width: 3rem;
}

.rankingImageDiv2 .rankingFace {
    width: 4.25rem;
}

.rankingImageDiv3 .rankingFace {
    width: 3rem;
}

.rankingImageDiv1 .rankingMedal {
    width: 1.14256rem;
    transform: translate(0.9rem, 0rem);
}

.rankingImageDiv2 .rankingMedal {
    width: 1.65275rem;
    transform: translate(1.2rem, 0.1rem);
}

.rankingImageDiv3 .rankingMedal {
    width: 1.16669rem;
    transform: translate(0.9rem, 0rem);
}

.rankingFace {}

.rankingMedal {
    position: absolute;
    bottom: 0;
}

.eachRankingImageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rankingNameDiv {
    margin-top: 0.3rem;
    color: var(--black);
    text-align: center;
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.rankingTimeDiv {
    margin-top: 0.19rem;
    color: var(--blue);
    text-align: center;
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rankingNumberDiv {
    margin-top: 0.19rem;
    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rankingJustBorder {
    width: 77.7%;
    margin: 0 auto;
    height: 0.0625rem;
    background-color: #E5E8EB;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
}

.eachRankingDiv {
    display: flex;
    justify-content: space-between;
    width: 86.666%;
    margin: 0 auto;
    /* padding-left: 0.94rem; */
    margin-bottom: 1.12rem;
}

.eachRankingDivBefore {
    display: flex;
    align-items: center;
    height: 100%;
}

.eachRankingNumberDiv {
    margin-right: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 2.5rem;
    align-items: center;
}

.eachRankingNumber {
    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eachRankingNumberArrow {
    height: 0.375rem;
    display: flex;
    align-items: center;
}

.eachRankingName {
    color: var(--black);
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eachRankingLocation {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eachRankingDivAfter {
    color: var(--gray);
    font-family: var(--font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.rankingBottomBtnDiv {
    border-top: 0.0625rem solid #E5E8EB;
}

.modalBody {
    padding-top: 3.75rem;
}

.modalJustBorder {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 0.0625rem;
    background-color: #E5E8EB;
}

.modalHeaderWrapper {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
}

.modalHeader {
    width: calc(86.666% + 1rem);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.regularScheduleBody {
    margin-top: 1.75rem;
}

.regularScheduleStatus {
    display: flex;
    align-items: center;
    width: 86.666%;
    margin: 0 auto;
}

.eachStatusDiv {
    display: flex;
    align-items: center;
}

.eachStatusNumber {
    margin-right: 0.38rem;
}

.eachStatusText {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.horizontalBar {
    height: 0.0625rem;
    width: 1rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background-color: var(--gray);
}

.notDoneNumber {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray);
    color: #FFF;
    text-align: center;
    font-family: var(--font);
    font-size: 0.4375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.zeroDiv {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zeroText {
    margin-top: 1.5rem;
    color: var(--black);
    text-align: center;
    font-family: var(--font);
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.regularScheduleListDiv {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 2rem;
}

.eachRegularScheduleDay {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0.75rem;
    border-bottom: 0.0625rem solid #E5E8EB;
    margin-bottom: 1rem;
}


.eachRegularScheduleContent {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.75rem;
}

.eachRegularScheduleFormatDiv {
    display: flex;
}

.eachFormat {
    border-radius: 0.375rem;
    background-color: #1E6EF4;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.375rem;
    align-items: center;
}

.smallCheckDiv {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.eachFormatText {
    color: #FFF;
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.suddenScheduleBody {
    padding-top: 2.81rem;
    width: 86.666%;
    margin: 0 auto;
}

.eachSuddenScheduleKind {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.75rem;
    border-bottom: 0.0625rem solid #E5E8EB;
    margin-bottom: 1rem;
}

.eachSuddenScheduleKindText {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eachSuddenScheduleStatusDiv {
    display: flex;
    align-items: center;
}

.eachSuddenScheduleContent {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.75rem;
}

.eachSuddenScheduleTime {
    display: flex;
    align-items: center;
}

.eachSuddenScheduleTimeText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.375rem;
    border-radius: 0.375rem;
    background-color: var(--gray2);
    color: #FFF;
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}


.eachSuddenScheduleTimeText.permitted {
    background-color: #1E6EF4;
}

.eachSuddenScheduleTimeArrowDiv {
    margin-left: 0.62rem;
    margin-right: 0.62rem;
}

.smallMenuBarDiv {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 2.25rem;
}

.smallMenuBarDiv2 {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 2.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.smallMenuBarWrapper {
    width: 47%;
}

.smallMenuBarWrapper2 {
    width: 45%;
}

.smallMenuBarWrapper3 {
    width: 70%;
}

.phoneInspectStatusInfo {
    display: flex;
    width: 86.666%;
    margin: 0 auto;
    margin-top: 1.44rem;
}

.phoneInspectStatusInfo .eachInfo {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.phoneInspectStatusInfo .eachInfoSvg {
    margin-right: 0.19rem;
}

.phoneInspectStatusInfo .eachInfoText {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.phoneInspectDiv {
    margin-top: 1.25rem;
}

.eachPhoneInspectDiv {}

.eachPhoneInspectWrapper {
    width: 86.666%;
    margin: 0 auto;
    display: flex;
}

.eachPhoneInspectClass {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    margin-right: 1rem;
    height: 1.625rem;
    display: flex;
    align-items: center;
    width: 2rem;
}

.eachPhoneInspectStatusDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eachPhoneInspectStatus {
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 50%;
    border: 0.125rem solid var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachPhoneInspectStatus.eachPhoneInspectFinalStatus1 {
    border-color: var(--blue);
}

.eachPhoneInspectStatus.eachPhoneInspectFinalStatus3 {
    border-color: #EF4452;
}

.eachPhoneInspectStatusLine {
    background-color: var(--gray);
    width: 0.125rem;
    flex-grow: 1;
}

.eachPhoneInspectRealInfo {
    margin-left: 0.63rem;
}

.eachPhoneInspectRealInfoText {
    height: 1.625rem;
    display: flex;
    align-items: center;
    color: var(--black);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eachPhoneInspectRealInfoMultiArrow {
    display: flex;
    align-items: center;
    margin-left: 0.38rem;
    margin-right: 0.38rem;
}

.eachPhoneInspectImgWrapper {
    margin-top: 0.56rem;
    display: flex;
    align-items: flex-end;
}

.eachPhoneInspectImg {
    width: 5.125rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.eachPhoneInspectNotImg {
    width: 5.125rem;
    height: 4rem;
    background-color: #F3F4F6;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.eachPhoneInspectImgTime {
    margin-left: 0.25rem;
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eachPhoneInspectKakaoWrapper {
    height: 1.44rem;
}

.kakaoDiv {
    display: flex;
    align-items: center;
    height: 100%;
}

.kakaoCircle {
    width: 0.9375rem;
    height: 0.9375rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FEC341;
    margin-right: 0.31rem;
}

.kakaoText {
    color: var(--black);
    font-family: Inter;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.eachPhoneChevronDiv {
    background-color: red;
    height: 0.75rem;
    position: relative;
}

.chevron {
    position: absolute;
    left: -0.49375rem;
    bottom: 0.625rem;
}

.locationViolation {
    width: 86.666%;
    margin: 0 auto;
    padding-top: 1.62rem;
}

.locationViolationTitle {
    display: flex;
    align-items: center;
}

.locationViolationTitleText {
    display: flex;
    align-items: center;
    color: var(--black);
    font-family: var(--font);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 0.5rem;
}

.locationViolationSubTitle {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.87rem;
}

.locationViolationList {
    margin-top: 3.31rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6%;
}

.locationViolationItem {
    background-color: #F3F4F6;
    border-radius: 0.875rem;
    aspect-ratio: 90/105;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.locationViolationItemTitle {
    color: var(--gray);
    font-family: var(--font);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.locationViolationItemSubTitle {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.37rem;
}

.locationViolationItemIcon {
    display: flex;
    justify-content: flex-end;
}

.noViolationDiv {
    width: 86.666%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4.5rem;
}

.noViolationText {
    margin-top: 2.5rem;
    padding-bottom: 3.19rem;
    color: var(--black);
    text-align: center;
    font-family: var(--font);
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.eachMyViolation {
    margin-top: 1.63rem;
}

.eachViolationTitle {
    width: 86.666%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.eachViolationTitleText {
    color: var(--black);
    font-family: var(--font);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 0.5rem;
}

.eachViolationWrapper {
    padding-top: 0.81rem;
    width: 86.666%;
    margin: 0 auto;
    display: flex;
}

.eachViolationLineWrapper {
    width: 24px;
    display: flex;
    justify-content: center;
}

.eachViolationLine {
    width: 0.25rem;
    border-radius: 0.375rem;
    background-color: #E3E8EB;
}

.eachViolationDemerit {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.44rem;
}

.eachViolationDescription {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
}

.eachViolationGraphWrapper {
    position: relative;
    margin-left: 0.63rem;
}

.eachViolationGraph {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 3.75rem;
    border-radius: 0.25rem;

    overflow: hidden;

    transition: all 500ms ease-out;
}

.eachViolationGraph.status {
    background-color: #EF4452;
}

.eachViolationGraph.sudden {
    left: 5.25rem;
    background-color: #FFC000;
}

.eachViolationGraph.regular {
    left: 10.5rem;
    background-color: #4FC578;
}

.eachViolationGraphStart {
    color: var(--black);
    text-align: center;
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachViolationGraphEnd {
    color: var(--black);
    text-align: center;
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachViolationGraphTitle {
    margin-left: 0.63rem;
    display: flex;
    padding-top: 0.75rem;
}

.eachViolationGraphTitleText {
    width: 3.75rem;
    display: flex;
    justify-content: center;
    margin-right: 1.5rem;
    color: var(--gray);
    text-align: center;
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dailyStarDiv {
    display: flex;
    align-items: center;
}

.dailyStarText {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.24rem;
}

.eachTestHistoryDiv {
    width: 86.666%;
    margin: 0 auto;
    margin-top: 1.25rem;
    display: flex;
}

.eachTestHistoryDiv0 {
    margin-top: 2rem;
}

.eachTestHistoryImgDiv {
    width: 3.37856rem;
    position: relative;
}

.homeworkDoneDiv {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.eachBookImg {
    width: 100%;
    object-fit: cover;
}

.eachTestBookDescriptionDiv {
    flex-grow: 1;
    margin-left: 0.63rem;
}

.eachTestBookTitle {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: var(--font);
    margin-bottom: 0.38rem;
}

.eachTestDay {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eachTestCorrectDiv {
    width: 100%;
    border-radius: 0.5rem;
    background: var(--backgray);
    margin-top: 0.38rem;
}

.eachTestCorrectWrapperDiv {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.38rem;
    padding-bottom: 0.38rem;
}

.eachTestCorrectDescription {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--gray);
    font-family: var(--font);
}

.eachTestCorrectText {
    margin-left: 0.5rem;
    padding-bottom: 0.075rem;
}

.eachTestCorrectDescriptionNon {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 0.38rem;
    padding-bottom: 0.38rem;
}

.eachTestCorrectDescriptionNon .eachTestCorrectText {
    color: #ef4452;
    font-family: var(--font);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0;
}

.eachTestDueDate {
    margin-top: 0.25rem;
    display: flex;
    justify-content: flex-end;
    color: var(--blue);
    text-align: right;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eachTestDueDate.notDoneHomework {
    color: #ef4452;
}

.wrongWordDiv {
    margin-top: 2.25rem;
}

.wrongWordDivWrapper{
    width: 86.666%;
    margin: 0 auto;
}

.hideWrongWordDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #000;
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrongWords {
    margin-top: 1.59rem;
}

.eachWrongWordDiv {
    margin-top: 0.75rem;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 1px 0.2px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0);
    padding-top: 1.69rem;
    padding-bottom: 0.88rem;
}

.eachWrongWordDiv0 {
    margin-top: 0rem;
}

.eachWrongWordWrapper {
    width: 87%;
    margin: 0 auto;
}

.eachWrongWordDivFirstLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eachWrongWordEnglish {
    color: #000;
    font-family: var(--font);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.eachWrongWordDay {
    color: var(--gray);
    text-align: right;
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.eachWrongWordKorean {
    margin-top: 1rem;
    color: #000;
    font-family: var(--font);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 2.2rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

}

.eachWeeklyAssignmentDiv{
    width: 86.666%;
    margin: 0 auto;
    margin-top: 1rem;
}

.eachWeeklyAssignmentTitleDiv{
    display: flex;
    align-items: center;
    margin-bottom: 1.06rem;
}

.eachWeeklyAssignmentTitle {
    color: #000;
    font-family: var(--font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eachWeeklyAssignmentSvgDiv{
    margin-right: 0.69rem;
}

.eachWeeklyAssignmentDiv0{
    margin-top: 2.25rem;
}

.eachWeeklyAssignmentDescriptionDiv{
    display: flex;
}

.eachWeeklyAssignmentBarDiv{
    width: 1.5rem;
    display: flex;
    justify-content: center;
    margin-right: 0.25rem;
}

.eachWeeklyAssignmentBar{
    width: 0.25rem;
    border-radius: 0.375rem;
    background-color: #e3ebeb;
}

.eachWeeklyAssignemtBookImgDiv{
    width: 3.1875rem;
    height: 3.1875rem;
    margin-right: 0.5rem;
}

.eachWeeklyAssignmentBookImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.eachWeeklyAssignmentBookName {
    color: #000;
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.31rem;
}

.eachWeeklyAssignmentBookDay {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 1.625rem;
    height: 1.8rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    width: 200px;
}

.eachWeeklyAssignmentBookAuthor {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.examinerName {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0.44rem;
}

.eachWeeklyAssingmentBookInfoNoHomeworkDiv{
    height: 3.375rem;
    display: flex;
    align-items: center;
}

.eachWeeklyAssignmentNoHomeWorkTest {
    color: var(--gray);
    font-family: var(--font);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 0.75rem;
}

.mySeatNumber{
    font-family: var(--font);
    color: #6E757D;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}