.voidHeader {
    height: 82px;
}

.headerBar{
    height: 176px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #383838;
    letter-spacing: -0.76px;
    font-size: 30.5px;
}


.houseSvg {
    width: 18px;
    margin-bottom: 2.5px;
    margin-right: 20px;
}

.rightChevron {
    width: 8px;
    margin-right: 18px;
}

.rightChevron.second{
    margin-left: 18px;
}

.currentMenuViewer{
    width: 1150px;
    margin: 0 auto;
    display: flex;
    height: 58px;
    align-items: center;
}

.currentMenuViewerText_2{
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #525252;
}

.currentMenuViewerBoarder{
    height: 1px;
    opacity: 0.6;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.22);
}

.titleText {
    margin-top: 125px;
    text-align: center;
    font-size: 35.5px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.89px;
    color: black;
    margin-bottom: 85px;
}

.notificationBox{
    width: 1150px;
    margin: 0 auto;
}

.notificationBox.forStoryBox{
    width: 700px;
    margin-top: 80px;
}

.notificationHeader{
    display: flex;
    height: 66.45px;
    border-top: 2px solid #000000;
    line-height: 66.45px;
    font-size: 17px;
    color: #222;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
}

.notificationHeader.forDescription{
    margin-top: 90px;
    border-bottom: 0;
    height: auto;
    line-height: initial;
    padding-top: 42px;
    font-size: 35.5px;
    color: black;
    line-height: 1.5em;
}

.headerText1{
    width: 143.99px;
    text-align: center;
}

.headerText2{
    flex-grow: 1;
    text-align: center;

}

.headerText3{
    width: 143.99px;
    text-align: center;

}

.headerText4{
    width: 143.99px;
    text-align: center;

}

.headerText5{
    width: 143.99px;
    text-align: center;
}

.notificationBodyRow{
    display: flex;
    height: 65.54px;
    line-height: 65.54px;
    color: #222;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
}

.notificationBodyRow.isTop{
    font-weight: 700;
}

.bodyText1{
    width: 143.99px;
    text-align: center;
    color: #333333;
}

.bodyText2{
    width: 574.06px;
    overflow: hidden;
    color: #333333;
    cursor: pointer;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bodyText2:hover span{
    border-bottom: 2px solid black;
}

.bodyText3{
    width: 143.99px;
    text-align: center;
    color: #333333;

}

.bodyText4{
    width: 143.99px;
    text-align: center;
    color: #333333;


}

.bodyText5{
    width: 143.99px;
    text-align: center;
    color: #333333;
}

.pagination{
    margin-top: 40px;
    margin-bottom: 120px !important;
    display: flex;
    justify-content: center;
}

.listDiv{
    width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 200px;
    margin-top: 20px;
}


.onlymobile{
    display: none;
}

.descriptionHeaderDescription{
    height: 39px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 55px;
}

.descriptionDivBox{
    display: flex;
    align-items: center;
}

.descriptionName{
    font-size: 15px;
    color: #333;
    font-weight: 700;
    letter-spacing: -.05px;
    color: #979899;
}

.dash{
    margin-left: 10px;
    margin-right: 10px;
    height: 13px;
    width: 1px;
    background-color: #dbdbdb;
    color: #979899;

}

.descriptionDate{
    font-size: 15px;
    color: #333333;
    color: #979899;

}

.howMany{
    color: #979899;
    font-size: 15px;
}

.howMany span{
    font-weight: 700;
}

.notificationBodyHtmlDiv{
    padding-bottom: 55px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 55px;
}

.toggleDiv{
    padding-top: 120px;
    width: 1150px;
    margin: 0 auto;
}

.titleDiv{
    padding-top: 20px;
    width: 1150px;
    margin: 0 auto;
}

.editorDiv{
    width: 1150px;
    padding-top: 30px;
    margin: 0 auto;
}

.submitBtnDiv{
    width: 1150px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 80px;
    display: flex;
    justify-content: flex-end;
}



.storyHeader{
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    color: rgb(51, 61, 75);
    line-height: 62px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 18px;
    white-space: pre-wrap;
}

.storyHeaderDescription{
    text-align: center;
}

.storyDate{
    font-size: 17px;
    font-weight: 500;
    line-height: 1.5;
    color: rgb(107, 118, 132);
}

.storyMainImgDiv{
    width: 700px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
}

.mainImg{
    width: 100%;
    border-radius: 16px;
}







@media only screen and (max-width : 1024px) {

    .onlymobile{
        display: block;
    }

    .onlyPC{
        display: none;
    }

    .notificationBox.forStoryBox{
        width: 92%;
        max-width: calc(100% - 48px);
        margin-top: 60px;
    }



    .editorDiv{
        width: 80%;
    }

    .pagination.onlymobile{
        width: 351px;
        margin: 0 auto;
        margin-bottom: 66.146px !important;
        margin-top: 56.25px;
        display: flex;
    }

    .voidHeader {
        height: 59px;

        height: 104px;
    }


    .headerBar{
        font-size: 24px;
    }
    
    .notificationBox{
        margin-top: 50px;
        width: 87.5%;
        border-top: 2px solid black;
    }

    .notificationBox.forDescription{
        border-top: 0;
    }

    .mobilenotificationBodyRow{
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        padding-top: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid #dddddd;
    }


    .mobileSubBodyDiv{
        padding: 2vw 0;
    }

    .mobilebodyText2{
        color: #222;
        font-size: 16.05px;
        line-height: 1.4em;
        font-weight: 700;
        padding: 0 1.5vw 0;


        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .mobileSubBodyDiv{
        padding: 3.4vw 1.5vw 0;
        display: flex;
        font-size: 14.175px;
        color: #222222;
        overflow: hidden;
    }

    .notificationHeader.forDescription{
        margin-top: 50px;
        padding-top: 21px;
        font-size: 21.5625px;
        line-height: 1.5em;
    }

    .descriptionHeaderDescription{
        height: auto;
        margin-top: 12px;
        margin-bottom: 40px;
        padding-right: 3px;
    }
    
    .descriptionName{
        font-size: 14px;
    }

    .descriptionDate{
        font-size: 14px;
    }

    .dash {
        height: 12px;
        margin-top: 2px;
    }

    .howMany{
        font-size: 14px;
    }

    .listDiv{
        width: 100%;
        margin-bottom: 78.172px;
    }


    .mobileTitleBox{
        width: 92%;
        max-width: calc(100% - 48px);
        margin: 0 auto;
    }


    .mobileTitleText{
        padding-top: 51px;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.4;
        color: #333d4b;
        text-align: center;
    }

    .mobileSubTitleText{
        font-size: 15px;
        line-height: 1.4;
        font-weight: 500;
        text-align: center;
        color: #6b7684;
        padding-top: 12px;
    }









    .storyHeader{
        text-align: center;
        font-size: 26px;
        font-weight: 500;
        color: rgb(25, 31, 40);
        line-height: 1.4;
        width: 90%;
        margin-bottom: 18px;
    }
    
    .storyHeaderDescription{
        text-align: center;
    }
    
    .storyDate{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: rgb(51, 61, 75);
    }
    
    .storyMainImgDiv{
        width: 100%;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mainImg{
        width: 100%;
        border-radius: 16px;
    }

}

@media only screen and (max-width : 350px) {
    .pagination{
        width: auto;
    }
}