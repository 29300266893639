.main {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 82px;
    box-shadow: 0px 2px 2px 0px rgba(62, 62, 62, 0.1);
    position: fixed;
    background-color: white;
    z-index: 999;
}

.main2 {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 82px;
    box-shadow: 0px 2px 2px 0px rgba(62, 62, 62, 0.1);
    position: fixed;
    background-color: white;
    z-index: 999;
}

.mainDiv{
    align-items: center;
    width: 1150px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.logoimg{
    width: 140px;
    cursor: pointer;
}


.menu {
    height: 100%;
    line-height: 82px;
}

.menuUl {
    display: flex;
}

.menuUl li {
    font-size: 16px;
    letter-spacing: -1.28px;
    padding-right: 24px;
    padding-left: 24px;
    cursor: pointer;
    font-weight: 500;
    color: #494747;
}

.hoverBar{
    display: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);;
    height: 82px;
    position: fixed;
    top: 75px;
}

.hoverBar.active{
    opacity: 1;
    display: flex;
    z-index: 999;
    justify-content: center;
}

.bottomMenu{
    height: 100%;
    display: flex;
    align-items: center;
}

.bottomMenuTitle {
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    padding-right: 50px;
    border-right: 3px solid black;
    margin-right: 50px;
}

.bottomSubMenu{
    cursor: pointer;
    margin-right: 40px;
    font-weight: 300;
}

.bottomMenu_1{

}

.bottomMenu_3{
}

.cateBox {
    font-weight: 400;
    position: absolute;
    background-color: white;
    width: 180px;
    text-align: center;
    left: 50%;
    transform: translate(-50%,0);
    font-size: 15px;
    line-height: normal;
    padding-top: 22px;
    border: 1px solid #eeeeee;
}

.cateSubMenu{
    padding-bottom: 22px;
}

.cateSubMenu:hover{
}

.cateSubMenuText{
    transition: color 300ms ease-in-out;
}

.cateSubMenuText:hover{
    font-weight: 500;
    /* color: blue;
    border-bottom: 1.5px solid blue; */
    border-bottom: 1.5px solid black;
}

.onlymobile{
    display: none;
}


@media only screen and (max-width : 1024px) {
    .none {
        display: none !important;
    }
    
    .onlyPC{
        display: none;
    }

    .onlymobile {
        display: block;
    }

    .main {
        padding-left: 24px;
        padding-right: 24px;
        height: 59px;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(30px);
        box-shadow: none;
        transition: backdrop-filter 50ms ease-in-out;
    }

    .main2 {
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
        height: 59px;
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 27, 55, 0.1);
        background-color: white;
    }


    .white {
        background-color: white;
        border-bottom: 1px solid rgba(0, 27, 55, 0.1);
    }

    .transparent {
        background-color: transparent;
        backdrop-filter: blur(0px);
    }

    .logoimg{
        width: 100px;
    }

    .newLogo{
        width: 110px;
    }

    .mainRightSection{
        display: flex;
        align-items: center;
        height: 100%;
    }

    .mobileRegisterBtn{
        color: black;
        background-color: #1b49af;
        width: 72px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 15px;
        font-size: 12px;
        font-weight: 400;
        margin-right: 12px;
    }

    .menubar {
        color: white;
        padding: 0 8px;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .headerLogoBar {
        align-items: center;
        display: flex;
        justify-content: space-between;
        height: 59px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .mobileMenuList{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 14px;
        line-height: 20px;
        color: rgb(66, 75, 88);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mobileSubMenuList{
        padding-left: 52px;
        padding-right: 20px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 14px;
        line-height: 20px;
        color: rgb(66, 75, 88);

    }









    /* 메뉴 하단 설정 */

    .main.white{
        box-shadow: none;
        border-bottom: none;
        border-bottom: 1px solid hsla(0,0%,95.7%,.9);
        backdrop-filter: unset;
    }

    .main2{
        box-shadow: none;
        border-bottom: none;
        border-bottom: 1px solid hsla(0,0%,95.7%,.9);
    }

    .bottomMenuBar{
        z-index: 999;
        position: fixed;
        top: 59px;
        background-color: hsla(0,0%,100%,.85);
        border-bottom: 1px solid hsla(0,0%,95.7%,.9);
        width: 100%;
    }

    .bottomMenuDiv{
        padding: 11px 0 11px 9px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .bottomMenuDiv::-webkit-scrollbar{
        background-color: #f5f5f5;
        width: 0px !important;
        height: 0px !important;
    }
    
    .bottomMenuDiv::-webkit-scrollbar-thumb{
        background-color: #bbbcb6;
        border-radius: 0px;
    }

    .listTab{
        display: flex;
    }
    
    .bottomMenuList{
        padding-left: 11px;
        padding-right: 14px;
        padding-top: 4px;
        padding-bottom: 2px;
        border-bottom: 2px solid transparent;
        font-weight: 500;
        font-size: 14px;
        flex-shrink: 0;
        color: #444;
        letter-spacing: -0.029em;
    }

    .bottomMenuList:hover{
        /* text-decoration-line:underline;
        text-underline-position: under;      */
    }

    .bottomMenuList{
        position: relative;
    }

    .dot1{
        position: absolute;
        top: -1px;
        right: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #1b49af;
    }

    .dot2{
        position: absolute;
        top: -1px;
        right: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #1b49af;
    }
}